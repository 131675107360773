<script>
  import errorsMixin from '@/mixins/errors.mixin'

  export default {
    name: 'formatExamProvider',
    mixins: [errorsMixin],

    inject: ['svc'],

    render() {
      return this.$scopedSlots.default({
        getFormattingExam: this.getFormattingExam,
      })
    },

    methods: {
      async getFormattingExam({examID, courseID}) {
        const exams = await this.svc.getConfig(courseID);
        const addedExam = exams.find(exam => exam.ID === examID);
        if (addedExam) {
          this.$emit('formatted', addedExam)
        } else {
          this.processError({message: 'Error exam added'})
        }
      }
    }
  }
</script>